import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

interface GetRefreshBookingApiResponse extends AxiosResponse {
  data: string
}

export interface GetRefreshBookingApiRequest
  extends Partial<AxiosRequestConfig> {
  bookingNo: string
}

export function getRefreshBookingApi(
  { bookingNo, ...config }: GetRefreshBookingApiRequest,
  axiosInstance: AxiosInstance,
): Promise<GetRefreshBookingApiResponse> {
  return axiosInstance({
    method: "get",
    url: `booking/save-mongo-booking/${bookingNo}`,
    ...config,
  })
}
