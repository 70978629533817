import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { ApiStatus, ResponseData } from "services/common"

interface RelocateGuestApiResponseData extends ResponseData {
  apiStatus: ApiStatus
  data: string
}

interface RelocateGuestApiResponse extends AxiosResponse {
  data: RelocateGuestApiResponseData
}

export interface RelocateGuestApiRequest extends Partial<AxiosRequestConfig> {
  params: {
    bookingNumber: string
    propertyId: string
  }
}

export function relocateGuestApi(
  { params, ...config }: RelocateGuestApiRequest,
  axiosInstance: AxiosInstance,
): Promise<RelocateGuestApiResponse> {
  return axiosInstance({
    method: "get",
    params,
    url: `admin/booking/relocate-guest`,
    ...config,
  })
}
