import { Icon } from "@chakra-ui/react"

const ReviewManagementIcon = () => {
  return (
    <Icon width='20px' height='20px' viewBox='0 0 20 20'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <path
          d='M10 5L11.195 7.31437L13.75 7.68563L11.875 9.375L12.5 11.875L10 10.4688L7.5 11.875L8.125 9.375L6.25 7.68563L8.875 7.31437L10 5Z'
          fill='#858282'
        />
        <path
          d='M11.085 18.75L10 18.125L12.5 13.75H16.25C16.4142 13.7502 16.5769 13.7181 16.7286 13.6554C16.8804 13.5926 17.0183 13.5006 17.1344 13.3844C17.2506 13.2683 17.3426 13.1304 17.4054 12.9786C17.4681 12.8269 17.5002 12.6642 17.5 12.5V5C17.5002 4.83578 17.4681 4.67312 17.4054 4.52135C17.3426 4.36959 17.2506 4.23169 17.1344 4.11557C17.0183 3.99944 16.8804 3.90738 16.7286 3.84465C16.5769 3.78192 16.4142 3.74975 16.25 3.75H3.75C3.58578 3.74975 3.42312 3.78192 3.27135 3.84465C3.11959 3.90738 2.98169 3.99944 2.86557 4.11557C2.74944 4.23169 2.65738 4.36959 2.59465 4.52135C2.53192 4.67312 2.49975 4.83578 2.5 5V12.5C2.49975 12.6642 2.53192 12.8269 2.59465 12.9786C2.65738 13.1304 2.74944 13.2683 2.86557 13.3844C2.98169 13.5006 3.11959 13.5926 3.27135 13.6554C3.42312 13.7181 3.58578 13.7502 3.75 13.75H9.375V15H3.75C3.08696 15 2.45107 14.7366 1.98223 14.2678C1.51339 13.7989 1.25 13.163 1.25 12.5V5C1.25 4.33696 1.51339 3.70107 1.98223 3.23223C2.45107 2.76339 3.08696 2.5 3.75 2.5H16.25C16.913 2.5 17.5489 2.76339 18.0178 3.23223C18.4866 3.70107 18.75 4.33696 18.75 5V12.5C18.75 13.163 18.4866 13.7989 18.0178 14.2678C17.5489 14.7366 16.913 15 16.25 15H13.2281L11.085 18.75Z'
          fill='#858282'
        />
      </svg>
    </Icon>
  )
}

export default ReviewManagementIcon
