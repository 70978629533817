import { Icon } from "@chakra-ui/react"

const FeeMappingIcon = () => {
  return (
    <Icon width='22px' height='22px' viewBox='0 0 22 22'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
      >
        <path
          d='M3 13C3.53043 13 4.03914 12.7893 4.41421 12.4142C4.78929 12.0391 5 11.5304 5 11C5 10.4696 4.78929 9.96086 4.41421 9.58579C4.03914 9.21071 3.53043 9 3 9C2.46957 9 1.96086 9.21071 1.58579 9.58579C1.21071 9.96086 1 10.4696 1 11C1 11.5304 1.21071 12.0391 1.58579 12.4142C1.96086 12.7893 2.46957 13 3 13ZM20 3C20.2652 3 20.5196 2.89464 20.7071 2.70711C20.8946 2.51957 21 2.26522 21 2C21 1.73478 20.8946 1.48043 20.7071 1.29289C20.5196 1.10536 20.2652 1 20 1C19.7348 1 19.4804 1.10536 19.2929 1.29289C19.1054 1.48043 19 1.73478 19 2C19 2.26522 19.1054 2.51957 19.2929 2.70711C19.4804 2.89464 19.7348 3 20 3ZM20 12C20.2652 12 20.5196 11.8946 20.7071 11.7071C20.8946 11.5196 21 11.2652 21 11C21 10.7348 20.8946 10.4804 20.7071 10.2929C20.5196 10.1054 20.2652 10 20 10C19.7348 10 19.4804 10.1054 19.2929 10.2929C19.1054 10.4804 19 10.7348 19 11C19 11.2652 19.1054 11.5196 19.2929 11.7071C19.4804 11.8946 19.7348 12 20 12ZM20 21C20.2652 21 20.5196 20.8946 20.7071 20.7071C20.8946 20.5196 21 20.2652 21 20C21 19.7348 20.8946 19.4804 20.7071 19.2929C20.5196 19.1054 20.2652 19 20 19C19.7348 19 19.4804 19.1054 19.2929 19.2929C19.1054 19.4804 19 19.7348 19 20C19 20.2652 19.1054 20.5196 19.2929 20.7071C19.4804 20.8946 19.7348 21 20 21Z'
          stroke='#858282'
          strokeWidth='2'
          strokeLinejoin='round'
        />
        <path
          d='M15 2H9V20H15M5 11H15'
          stroke='#858282'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  )
}

export default FeeMappingIcon
