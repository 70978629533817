import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { Pageable, ResponseData } from "services/common"

export interface BookingDetailType {
  bookedDate: number
  bookingId: number
  bookingNo: string | undefined
  bookingStatus: string
  checkInDate: string[]
  checkOutDate: string[]
  insuranceAmount: number
  isPayoutActive: boolean
  isSplited: number
  isTransfer: boolean
  pmcId: string
  pmsReservationNumber: string
  pmsUnitId: string
  promoCodeAppliedAmount: number
  promoCodeName: string
  propertyName: string
  refundAmount: undefined | null | number
  splitUserVO: []
  status: string
  transferSendDate?: string | number
  travelerName: string
  travelerPaymentAmount: undefined | null | number
  stripeChargeDueDate: number
}

interface GetPaginatedBookingsResponseDataData extends Pageable {
  content: BookingDetailType[]
}

interface GetPaginatedBookingsResponseData extends ResponseData {
  data: GetPaginatedBookingsResponseDataData
}

interface GetPaginatedBookingsResponse extends AxiosResponse {
  data: GetPaginatedBookingsResponseData
}

export interface GetPaginatedBookingsRequest
  extends Partial<AxiosRequestConfig> {
  params: {
    keyword: string
    noOfRecords: number
    pageNo: number
    pmcId: string | number
    state: string
    status: string
  }
}

export function getPaginatedBookingsApi(
  { params, ...config }: GetPaginatedBookingsRequest,
  axiosInstance: AxiosInstance,
): Promise<GetPaginatedBookingsResponse> {
  return axiosInstance({
    method: "get",
    params,
    url: `newadmin/booking`,
    ...config,
  })
}
