import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { StagedPaymentType } from "api/bookingDashboard/getPaymentBookingDetailApi"
import {
  PaymentChargeLogArray,
  PaymentTransferLogArray,
} from "api/bookingDashboard/getSingleBookingDetailApi"

export interface BookingFeesType {
  applied: null | boolean
  currency: null | string
  description: string
  displayAsRent: null | number
  feeId: null | number
  feeCode: null | string
  id: number
  optional: null | string
  value: number
}

export interface BookingSplitPromoCode {
  promoCode: string
  value: null | number
}

export interface BookingAddressObject {
  address: string
  addressId: number
  city: string
  country: string
  emailId: string
  firstName: string
  isDifferentAddress: boolean
  lastName: string
  mobileNumber: string
  dialerCode: string
  nickName: string | null
  pronouns: string | null
  state: string
  userId: number
  zipCode: number | string | undefined
}

export interface BookingBillingAddressObject {
  address: string
  addressId: number
  city: string
  country: string
  emailId: string
  firstName: string
  isDifferentAddress: boolean
  lastName: string
  mobileNo: string
  nickName: string | null
  pronouns: string | null
  userId: number
  state: string
  zipCode: number | string | undefined
}

export interface BookingSplitUser {
  addressId: number
  bookingId: number
  bookingNumber: string
  bookingUserId: number
  createdAt: number
  dialerCode: string
  firstName: string
  insuranceAmount: number
  isDeducted: number
  isOwner: number
  mailSent: number
  mobileNumber: string
  promoCode: null | BookingSplitPromoCode
  promoCodeAppliedAmount: number
  refundAmount: null | number
  refundProtect: boolean
  isInsuranceCancel: boolean
  status: string
  totalPaidByTraveler?: number
  transferType: string
  travelerBookingEmail: string
  travelerChargeAmount: number
  travelerPaymentAmount: number
  userId: number
  whimstayInsuranceAmount: number
}

export interface BookingTaxes {
  currency: null | string
  description: string
  id: number
  percent: number
  taxId: null | number
  type: null | number
  value: number
}

export interface Payments {
  wmChargeId: number
  bookingId: number
  bookingNo: string
  chargeAmount: number
  currency: string
  groupId: null | number | string
  isActive: boolean
  isCapture: boolean
  isChargeSuccessful: boolean
  isSplitBookingCharge: boolean
  logCharacter: null | number | string
  paymentDate: string
  status: string
  stripeChargeId: null | string
  stripeCustomerId: string
  chargeCaptureAmount: number
  refundAmount: number
  stripeIntentId: string
  paymentType: string
  travellerId: number
  modifiedId: null | number | string
}

export interface Payouts {
  transactionId: number
  bookingNo: string
  currency: string
  isActive: boolean
  isTransactionReverse: boolean
  isTransactionSucessfull: boolean
  logCharacter: null | number | string
  paymentDate: null | string
  status: string
  pmcId: string
  stripeTranferId: null | string
  transferAmount: number
  transferSendDate: string
  transferType: string
  transferReversedAmount: number
  note: null | string
  reason: null | string
  modifiedId: null | number | string
}

export interface AllBookingType {
  bookingId: string
  bookingDate: number
  checkInDate: string
  checkOutDate: string
  status: string
  note: string
  state: string | null
  addressId: number
  billingAddressId: number
  paymentMode: string
  discountPercentApplied: number | null | string
  childNumber: number
  companyName: null | string
  contactEmail: null | string
  cancelationFee: number
  cancellationDate: null | string
  refundPaidDate: null | string
  payoutReversalDate: null | string
  bookingNumber: string
  pmsReservationNumber: string
  id: string
  adultNumber: number
  petNumber: number
  promoCodeName: null | string
  currency: string
  bookingAmount: number
  travelerPaymentAmount: number
  promoCodeAppliedAmount: number | null
  affirmFee: number
  nightCount: number
  userId?: number
  whimstayHostServiceFeeAmount: number
  whimstayTravelerServiceFeeAmount: number
  rentAmount: number
  optionalFeeAmount: number | null
  requiredFeeAmount: number
  taxAmount: number
  travelerId: string
  pmcId: string
  pmsUnitId: string
  propertyName: string
  propertyCountry: string
  propertyState: string
  propertyFullAddress: string
  pmsSystem: string
  hostEmail: string
  hostMobile: string
  hostName?: null | string
  isSplited: number
  insuranceAmount: number
  isInsuranceCancel: boolean
  splitUserVO: BookingSplitUser[]
  paymentChargeLog: PaymentChargeLogArray[] | null
  paymentTransferLog: PaymentTransferLogArray[] | null
  bookingBillingAddress: BookingBillingAddressObject
  bookingAddress: BookingAddressObject
  stagedPaymentTransaction: StagedPaymentType[] | null
  policyNumber: null | string
  insuranceType: string | null
  fees: BookingFeesType[] | null
  taxes: BookingTaxes[] | null
  cancelReason: null | string
  refundResionType: null | string
  partialRefund: boolean
  refundAmount?: null | undefined | number
  stripFee: number
  createdDt: number
  payouts: Payouts[]
  payments: Payments[]
  pdp: string | null
  pdpFees: number | null
  travelerAdditionalCharge: { [key: string]: number }
  hostAdditionalCharge: { [key: string]: number }
  hostPayoutIncentive: number | null
  hostServiceFeeIncentive: number | null
  incentive: number
  paymentStatus: string
  stripeChargeDueDate: number
}

interface GetAllBookingDetailResponseData extends AxiosResponse {
  data: AllBookingType[]
}

interface GetAllBookingDetailResponse extends AxiosResponse {
  data: GetAllBookingDetailResponseData
}

export interface GetAllBookingDetailRequest
  extends Partial<AxiosRequestConfig> {
  bookingNumber: string
}

export function getAllBookingDetailApi(
  { bookingNumber, ...config }: GetAllBookingDetailRequest,
  axiosInstance: AxiosInstance,
): Promise<GetAllBookingDetailResponse> {
  return axiosInstance({
    method: "get",
    url: `admin/booking/all?bookingNumber=${bookingNumber}`,
    ...config,
  })
}
