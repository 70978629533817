import { decodeHTML } from "entities"

export const isObject = (value: unknown) => {
  const type = typeof value
  return value != null && (type === "object" || type === "function")
}

export const markStates = (
  sobj: Record<string, unknown>,
  self: Record<string, unknown>,
) => {
  if (!isObject(sobj)) {
    throw new Error("mark: invalid sobj, exepect a object")
  }
  const selfKeys = Object.keys(self)

  Object.entries(sobj).forEach(([key, val]) => {
    if (!selfKeys.includes(key)) return false
    if (!Array.isArray(val) && isObject(val) && self[key] !== null) {
      // eslint-disable-next-line no-param-reassign
      self[key] = Object.assign(self[key] as object, val)
    } else {
      // eslint-disable-next-line no-param-reassign
      self = Object.assign(self, { [key]: val })
    }
  })

  return false
}

export const customSlugifyPropertyName = (
  word: string,
  replacement?: string,
) => {
  return (
    word
      ?.replace(/[^a-zA-Z\d ]/g, replacement ? replacement : "")
      .trim()
      .replace(/ /g, "-")
      .replace(/--/g, "-") || "Whimstay"
  )
}

export function generateDatabaseDateTime(date: number, isShowFullTime = false) {
  const p = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
    timeZone: "UTC",
  })
    .formatToParts(date)
    .reduce(
      (acc, part) => {
        // eslint-disable-next-line no-param-reassign
        acc[part.type] = part.value
        return acc
      },
      {
        year: undefined,
        month: undefined,
        day: undefined,
        hour: undefined,
        minute: undefined,
        second: undefined,
      },
    )
  if (isShowFullTime) {
    const hour = parseInt(String(p?.hour || 0), 10)
    const dayPeriod = hour >= 12 ? "PM" : "AM"
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${p.month}/${p.day}/${p.year} ${p.hour}:${p.minute}:${p.second}  ${dayPeriod}`
  } else {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${p.month}/${p.day}/${p.year} ${p.hour}:${p.minute}`
  }
}

export function formattedDate(date: string | number) {
  return new Date(date).toLocaleDateString("en", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "UTC",
    hour12: false,
  })
}

export function convertDateToLocalTimeZone(date: Date) {
  const convertedDate = new Date(date)
  const year = convertedDate.getFullYear()
  const month = (convertedDate.getMonth() + 1).toString().padStart(2, "0")
  const day = convertedDate.getDate().toString().padStart(2, "0")

  return `${year}-${month}-${day}`
}

export function convertUnixTimestampToDateString(timestamp: number) {
  const date = new Date(timestamp)
  const month = (date.getMonth() + 1).toString().padStart(2, "0") // Months are 0-based
  const day = date.getDate().toString().padStart(2, "0")
  const year = date.getFullYear()

  return `${month}-${day}-${year}`
}

export function convertUnixTimestampToPST(utcDateString: number) {
  // Create a Date object from the Unix timestamp
  const utcDate = new Date(utcDateString)

  // Set the timezone to Pacific Time (PST8PDT)
  const pst8PdtDateString = utcDate.toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  })

  return pst8PdtDateString
}

export function decodeHTMLEntities(text: string): string {
  return decodeHTML(text)
}

export const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-\d]+\.)+[a-z]{2,}))$/i,
  )
}

// Function to parse a number-based Unix timestamp (seconds or milliseconds)
function parseTimestamp(timestamp: number): Date {
  // If timestamp is less than 10 digits, assume it's in seconds and convert to milliseconds
  return new Date(timestamp < 10000000000 ? timestamp * 1000 : timestamp)
}

export function calculateCountdown(targetTimestamp: number) {
  try {
    const currentTime = new Date()
    const targetTime = parseTimestamp(targetTimestamp)

    // Get the difference in milliseconds
    const diff = targetTime.getTime() - currentTime.getTime()

    if (diff <= 0) {
      return { hours: 0, minutes: 0, seconds: 0 } // Return 0 if countdown is over
    }

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(diff / (1000 * 60 * 60))
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((diff % (1000 * 60)) / 1000)

    return { hours, minutes, seconds }
  } catch (error) {
    return null
  }
}
