import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { Pageable, ResponseData, SortDirectionType } from "services/common"

export interface ReviewReportDetailsType {
  additionalComments: string
  reportingReasons: string[]
}

export interface ReviewDataType {
  review: string
  admin_feedback: string | null
  survey_ratings: number | null
  survey_id: number
  property_name: string
  status: string
  user_name: string
  created_at: number
  booking_number: string
  updated_dt: number
  review_report_details: string | null
}

interface GetPaginatedReviewData extends Pageable {
  content: ReviewDataType[]
}

interface GetPaginatedReviewResponseData extends ResponseData {
  data: GetPaginatedReviewData
}

interface GetPaginatedReviewResponse extends AxiosResponse {
  data: GetPaginatedReviewResponseData
}

export interface GetPaginatedReviewRequest extends Partial<AxiosRequestConfig> {
  params: {
    keyword: string
    pageNo: number
    noOfRecords: number
    sortDirection: SortDirectionType
    sortBy: string
    status: string
  }
}

export function getPaginatedReviewApi(
  { params, ...config }: GetPaginatedReviewRequest,
  axiosInstance: AxiosInstance,
): Promise<GetPaginatedReviewResponse> {
  return axiosInstance({
    method: "get",
    params,
    url: `newadmin/reviews`,
    ...config,
  })
}
