import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { ApiStatus } from "services/common"

interface CapturePaymentResponse extends AxiosResponse {
  data: {
    data: boolean
    message: string
    apiStatus: ApiStatus
  }
}

export interface CapturePaymentRequest extends Partial<AxiosRequestConfig> {
  bookingNumber: string
}

export function capturePaymentApi(
  { bookingNumber, ...config }: CapturePaymentRequest,
  axiosInstance: AxiosInstance,
): Promise<CapturePaymentResponse> {
  return axiosInstance({
    method: "get",
    url: `/booking/auto-capture-booking?bookingNo=${bookingNumber}`,
    ...config,
  }) as Promise<CapturePaymentResponse>
}
