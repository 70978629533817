import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { Pageable, ResponseData, SortDirectionType } from "services/common"

export interface FeeCriteriaDataType {
  createdDt: number
  feeName: string
  feesCategory: string
  id: string
  updatedDt: number
}

interface GetPaginatedFeeCriteriaData extends Pageable {
  content: FeeCriteriaDataType[]
}

interface GetPaginatedFeeCriteriaResponseData extends ResponseData {
  data: GetPaginatedFeeCriteriaData
}

interface GetPaginatedFeeCriteriaResponse extends AxiosResponse {
  data: GetPaginatedFeeCriteriaResponseData
}

export interface GetPaginatedFeeCriteriaRequest
  extends Partial<AxiosRequestConfig> {
  params: {
    keyword: string
    pageNo: number
    noOfRecords: number
    sortDirection: SortDirectionType
    category: string | null
  }
}

export function getPaginatedFeeCriteriaApi(
  { params, ...config }: GetPaginatedFeeCriteriaRequest,
  axiosInstance: AxiosInstance,
): Promise<GetPaginatedFeeCriteriaResponse> {
  return axiosInstance({
    method: "get",
    params,
    url: `newadmin/fee-criteria`,
    ...config,
  })
}
