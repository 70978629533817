import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { CheckedItem, CheckedPayoutItem } from "pages/NewBookingPage/common"

import { SingleBookingType } from "./getSingleBookingDetailApi"

export interface ModifyRequest {
  policyNumber: string | null | number
  bookingNumber: string
  checkInDate: string | undefined
  checkOutDate: string | undefined
  adultNumber: number
  petNumber: number
  childNumber: number
  paymentReasonType: string | null
  paymentNote: string
  payoutReasonType: string | null
  payoutNote: string
  invoiceId: number | string
  rentAmount: number
  hostFees: Record<string, string> | null
  taxes: number
  whimstayTravelerServiceFee: string | number
  whimstayHostServiceFee: string | number
  travelerAdditionalCharge: Record<string, string> | null
  hostAdditionalCharge: Record<string, string> | null
  refundTravalerInsurance: number
  hostPayoutAmount: number
  travelerPaymentAmount: number
  hostPayouts: CheckedPayoutItem[]
  travelerPayments: CheckedItem[]
  travelerStagedPayments: { id: number; paymentAmount: number }[]
  invoice: boolean
  sendEmail: boolean
  isCancel: boolean
  isCancelInsurance: boolean
  note: string
  isForceCancel: boolean
}

interface ModifyBookingResponse extends AxiosResponse {
  data: SingleBookingType
}

export interface ModifyBookingRequest extends Partial<AxiosRequestConfig> {
  data: ModifyRequest
}

export function newModifyBookingApi(
  { data, ...config }: ModifyBookingRequest,
  axiosInstance: AxiosInstance,
): Promise<ModifyBookingResponse> {
  return axiosInstance({
    data,
    method: "post",
    url: `admin/booking/modify`,
    ...config,
    timeout: 180000,
  }) as Promise<ModifyBookingResponse>
}
